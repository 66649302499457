import React, { useState } from "react";
import { Button } from "primereact/button";
import styles from "./User.module.scss";
import UserDialog from "./UserDialog";

const UserHeading = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [addUser,setAdduser] = useState(false)
  const addNewUserHandler = () => {
    setShowDialog(true);
    setAdduser(true);
  };
  return (
    <div>
      <div className="grid py-3">
        <div className="col-6">
          <h2 className={`my-0 pt-2 ${styles.userHeading}`}>Users Overview</h2>
        </div>
        <div className="col-6 text-right">
          <Button
            label="Add New User"
            className={styles.newuser_btn}
            onClick={addNewUserHandler}
          />
        </div>
      </div>
      {showDialog && (
        <UserDialog visible={showDialog} onHide={() => setShowDialog(false)} flag={addUser}/>
      )}
    </div>
  );
};

export default UserHeading;
