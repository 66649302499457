import React from 'react'
import UserHeading from './UserHeading'
import UserList from './UserList'

const User = () => {
  return (
    <div>
      <UserHeading/>
      <UserList/>
    </div>
  )
}

export default User
